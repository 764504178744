export function usePartnerNav() {
  const navItems = computed<NavItem[]>(() => [
    {
      icon: 'chart-line',
      label: 'Active Accounts',
      path: wrapPathWithParentOrg('/dashboard'),
    },
    {
      icon: 'rocket',
      label: 'Lead Generation',
      path: wrapPathWithParentOrg('/dashboard/leads'),
    },
    {
      icon: 'page',
      label: 'Template Library',
      path: wrapPathWithParentOrg('/dashboard/templates'),
    },
    {
      icon: 'building-outline',
      label: 'Partner Profile',
      path: wrapPathWithParentOrg('/dashboard/profile'),
    },
    {
      icon: 'eye',
      label: 'View an Org',
      action: 'open:org-switcher',
    },
  ])

  return { navItems }
}
