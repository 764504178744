<template>
  <AtroContent
    :class="[
      'sticky left-0 top-10 z-10 w-[var(--home-sidebar-width)] space-y-8',
      {
        'h-[calc(100svh-7rem)]': showSubscribeBanner,
        'h-[calc(100svh-4rem)]': !showSubscribeBanner,
      },
    ]"
    direction="col"
    justify="between"
    wrap="nowrap"
    v-auto-expand="{ topOffset: 40, bottomOffset: 24 }"
  >
    <AtroContent v-auto-animate class="w-full" direction="col" wrap="nowrap">
      <div class="relative text-primary">
        <AtroIcon
          v-if="!org?.partnerProfile?.logo"
          class="w-44"
          name="atro-logo-horizontal"
        />
        <NuxtImg v-else :src="org.partnerProfile.logo" class="max-h-26" />
        <AtroSpan v-if="isPreview" semibold size="xs">
          Preview: {{ previewBranchName }}
        </AtroSpan>
        <AtroHeading
          v-if="isPartnerView"
          class="mt-4 font-light text-atro-gray"
          size="sm"
          text="Partner Dashboard"
        />
      </div>

      <AtroContent
        v-if="showSideBarActions && isAdminOrOwner && isAdminView"
        class="mt-10"
        direction="col"
        wrap="nowrap"
      >
        <NuxtLink :to="wrapPathWithOrg('/score')">
          <ScoreCard
            bordered
            hoverable
            shadowed
            class="mb-6"
            subtitle="Overall Security Protection"
            type="card"
            title="Organization Score"
            :score="org?.score"
          />
        </NuxtLink>

        <PopoverWalkthrough placement="bottom-start" step-key="marketplace">
          <AtroButton
            icon-right="plus"
            text="Secure Something"
            @click="openMarketplace()"
          />
        </PopoverWalkthrough>
      </AtroContent>

      <NavList v-if="isPartnerView" class="mt-12" :nav-items />
    </AtroContent>

    <AtroContent v-if="user" class="relative w-full space-y-8" direction="col">
      <AtroContent
        v-if="showSideBarActions && isAdminOrOwner"
        v-tooltip="onboardingAssignedTooltip"
        class="group w-full cursor-pointer space-x-2 text-atro-gray-1"
        items="center"
        wrap="nowrap"
        @click="toggleView()"
      >
        <AtroIcon class="h-6 w-6" name="rotate" />
        <AtroSpan
          semibold
          class="focus-visible:text-primary group-hover:text-primary"
          tabindex="0"
          :text="navToggleText"
          @keydown.space.prevent="toggleView()"
        />
        <ClientOnly>
          <AtroDot
            v-if="navToggleCount > 0"
            class="mt-0.5 bg-atro-red"
            size="md"
          />
        </ClientOnly>
      </AtroContent>

      <UserMenu />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import NuxtImg from '~/mocks/components/NuxtImg.vue'

const router = useRouter()
const { org } = useCurrentOrg()
const { showSubscribeBanner } = useCurrentSubscription()
const { isAdminOrOwner, user } = useCurrentUser()
const { isAdminView, navToggleCount, toggleView, view: userView } = useHomeNav()
const { layoutView } = useLayoutConfig()
const { navItems } = usePartnerNav()
const { onboardingAssignedTooltip } = useTooltips()
const { isPreview, previewBranchName } = useVercelPreview()
const { openMarketplace } = useMarketplace()
useHasBottomToolbar({ mobileOnly: true })

const isPartnerView = computed(() => layoutView.value === 'partner')
const navToggleText = computed(() => {
  switch (userView.value) {
    case 'admin':
      return 'Show assigned to me'
    case 'member':
      return 'Go to admin console'
    default:
      break
  }
})
const showSideBarActions = computed(
  () => router.currentRoute.value.name === 'orgs-slug',
)
</script>
