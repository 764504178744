export default function useTooltips() {
  const isMobile = useIsMobile()
  const { navToggleCount, view } = useHomeNav()
  const { hasSeenFlowAssigned } = useOnboarding()

  const onboardingAssignedTooltip = computed(() => {
    return {
      content: 'Something is assigned to you',
      delay: { show: 1500 },
      distance: 20,
      placement: isMobile.value ? 'bottom' : 'right',
      shown:
        !hasSeenFlowAssigned.value &&
        !!navToggleCount.value &&
        view.value === 'admin',
      triggers: [],
    }
  })

  return {
    onboardingAssignedTooltip,
  }
}
