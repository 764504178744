<template>
  <AtroContent
    shrink
    :class="[
      'w-full max-w-[1712px] sm:p-10 sm:pb-6',
      { 'h-full': layoutConfig.fullHeight },
    ]"
    wrap="nowrap"
    :direction="$isMobile.value ? 'col' : 'row'"
  >
    <MobileNavBar v-if="$isMobile.value" />
    <HomeSideBar v-else class="mr-20 pr-2" />

    <div
      class="flex w-full min-w-0 flex-1 flex-col px-4 py-4 sm:h-full sm:px-0 sm:py-0"
    >
      <NavList v-if="hasNavList" :nav-items class="mb-4" />
      <slot />
    </div>
  </AtroContent>
</template>

<script setup lang="ts">
const { navItems } = usePartnerNav()
const { layoutView, layoutConfig } = useLayoutConfig()

const isMobile = useIsMobile()

const hasNavList = computed(
  () => layoutView.value === 'partner' && isMobile.value,
)
</script>
