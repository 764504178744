<template>
  <AtroContent
    class="sticky left-0 top-0 z-10 h-16 w-full bg-white/75 pr-4 backdrop-blur-sm"
    justify="between"
    items="center"
    wrap="nowrap"
  >
    <AtroContent class="h-full" items="center" justify="center">
      <AtroButton class="!p-4" type="flat" @click="openDrawer()">
        <AtroIcon class="h-6 w-6 text-primary" name="bars" />
      </AtroButton>
    </AtroContent>

    <AtroContent
      shrink
      class="absolute left-1/2 h-full -translate-x-1/2"
      items="center"
    >
      <AtroIcon class="h-6 w-24 text-primary" name="atro-logo-horizontal" />
    </AtroContent>

    <AtroContent
      v-if="showViewToggle"
      v-tooltip="onboardingAssignedTooltip"
      class="rounded-2xl bg-atro-warm-gray-2 p-2"
      items="center"
      justify="center"
      @click="toggleView()"
    >
      <AtroIcon class="h-6 w-6 text-primary" :name="toggleViewIcon" />
      <ClientOnly>
        <AtroCountBubble
          size="lg"
          :class="[
            'ml-2 text-white',
            navToggleCount === 0 ? 'bg-atro-green' : 'bg-atro-red',
          ]"
          :count="navToggleCount"
        />
      </ClientOnly>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
const { isAdminOrOwner } = useCurrentUser()
const { navToggleCount, toggleView, view } = useHomeNav()
const { openDrawer } = useMobileDrawer()
const { layoutView } = useLayoutConfig()
const { onboardingAssignedTooltip } = useTooltips()

const showViewToggle = computed(
  () => isAdminOrOwner.value && layoutView.value !== 'partner',
)
const toggleViewIcon = computed<IconName>(() =>
  view.value === 'admin' ? 'user' : 'building',
)
</script>
